import { paymentConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const DataPaymentGetPlans = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_GETPLANS_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case paymentConstants.PAYMENT_GETPLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case paymentConstants.PAYMENT_GETPLANS_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case paymentConstants.PAYMENT_SELECTEDPLAN_USER_SUCCESS:
      return {
        ...state,
        planUserSeleted: action.payload,
      };
    case paymentConstants.PAYMENT_RESETPLAN_USER:
      return {
        ...state,
        planUserSeleted: null,
      };
    default:
      return state;
  }
};

export const DataPaymentRegisterClient = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_REGISTERCLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case paymentConstants.PAYMENT_REGISTERCLIENT_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    case paymentConstants.PAYMENT_REGISTERCLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTERCLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DataPaymentRegisterClientPlan = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const DataPaymentGetTransactionCounts = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DataPaymentRegTransactionCounts = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_REGISTERTRANSACTIONCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTERTRANSACTIONCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTERTRANSACTIONCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DataPaymentGetBillings = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_GETBILLING_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_GETBILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_GETBILLING_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const RegisterBillingInformation = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_REGISTER_BILLING_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTER_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_REGISTER_BILLING_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case paymentConstants.PAYMENT_REGISTER_BILLING_RESET:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const GetRegimenFiscal = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.PAYMENT_GET_REGIMENFISCAL_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case paymentConstants.PAYMENT_GET_REGIMENFISCAL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case paymentConstants.PAYMENT_GET_REGIMENFISCAL_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};