import { BodySecurityRequestForceEncripted } from "../utils/encripted";

export class Config {
  static setParameters(data, encryptedBody, pKey, securityData) {
    //Setear parámetros para solicitudes a api ***Método genérico***
    /*añadir parámetros solo si se cambia la estructura del objeto.
    Objéto genérico para todas las solicitudes a cualquier API*/
    return {
      Body: data,
      EncryptedBody: encryptedBody,
      PKey: pKey,
      SecurityData: securityData,
    };
  }

  static setParametersForceEncripted(data, force = false) {
    //Setear parámetros para solicitudes a api Encriptacion  ***Método genérico***
    /*añadir parámetros solo si se cambia la estructura del objeto.
    Objéto genérico para todas las solicitudes a cualquier API*/
    if (force) {
      return JSON.stringify(BodySecurityRequestForceEncripted(data));
    } else {
      return data;
    }
  }

  static configAuth() {
    //Configuración de solicitud que incluye token de autorización
    return {
      headers: {
        //Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
        Authorization: `${sessionStorage.getItem("token")}`, //Se añade Token   Content-Type: multipart/form-data; boundary=something
      },
    };
  }

  static configAuthFile() {
    //Configuración de solicitud que incluye token de autorización
    return {
      headers: {
        //Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        Accept: "application/json", //JSON
        "Content-Type": "multipart/form-data; boundary=1620255546726", //Esperamos un json con configuración UTF-8
        Authorization: `${sessionStorage.getItem("token")}`, //Se añade Token
      },
    };
  }

  static configNoAuth() {
    //Configuración de headers a enviar al consumir cualquier API ***solicitud excluyendo token de autorización***
    return {
      headers: {
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
      },
    };
  }

  static configNoAuthDoc() {
    //Configuración de headers a enviar al consumir cualquier API ***solicitud excluyendo token de autorización***
    return {
      responseType: "blob",
      headers: {
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
      },
    };
  }

  static configDoc() {
    //Configuración de headers a enviar al consumir cualquier API ***solicitud excluyendo token de autorización***
    return {
      responseType: "blob",
      headers: {
        //Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
        Authorization: `${sessionStorage.getItem("token")}`, //Se añade Token   Content-Type: multipart/form-data; boundary=something
      },
    };
  }

  static getTokenCurrentUser() {
    return sessionStorage.getItem("token");
  }
}
