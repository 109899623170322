/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  styled,
  tooltipClasses,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CapchaComponent from "../../components/CapchaComponent.js";
import { useDispatch, useSelector } from "react-redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../components/CustomButton";
import { InputField } from "../../components/InputField";
import {
  GetPlans,
  RegisterClient,
  RegisterClientPaymentPlan,
  ResetNewCliente,
  ResetRegisterBilling,
  SeletedPlanUser,
} from "../../store/actions/PaymentActions";
import Spinner from "../../components/Spinner";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AlertCustom } from "../../components/AlertCustom";
import { ToastNotification } from "../../components/toastNotification";
import { CustomInputField } from "../../components/CustomInputField";
import { ResetProjectClientPlan, ResetStripeKey } from "../../store/actions";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export const RegistrationPlans = (props) => {
  let { idPlanUser } = useParams();
  const formMethods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeRedirection, setActiveRedirection] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [cleanData, setCleanData] = useState(false);

  const stateRedux = useSelector((state) => state);
  const statePlanUser = useSelector((state) => state.DataPaymentGetPlans);
  const stateRegisterPaymentClientePlan = useSelector(
    (state) => state.DataPaymentRegisterClientPlan
  );
  const stateRegisterClient = useSelector(
    (state) => state.DataPaymentRegisterClient
  );

  useEffect(() => {
    dispatch(ResetNewCliente());
    dispatch(ResetProjectClientPlan());
    dispatch(ResetRegisterBilling());
    dispatch(ResetStripeKey());
    dispatch(
      GetPlans({
        referenceData: "",
        productId: 4,
      })
    );
  }, []);

  console.log("planSeleted", new Date(), new Date().getTime());

  const onSubmit = (data) => {
    console.log("datadata", data);
    setLoading(true);
    try {
      const ochocaracteres = /.{8,}/.test(data.password);
      const mayymin =
        /(?:[A-Z])/.test(data.password) && /(?:[a-z])/.test(data.password);
      const numeros = /(?:\d)/.test(data.password);
      const noespecial = /[^!?A-Za-z\d]/.test(data.password);
      const espacios = /\s/.test(data.password);
      console.log(ochocaracteres, mayymin, numeros, noespecial);
      const valida = ochocaracteres && mayymin && numeros && noespecial;
      if (valida === false || espacios) {
        AlertCustom({
          icon: "error",
          title: "Revisa tu contraseña",
          text: `Revisa que tu contraseña cumpla lo siguiente: ${
            espacios ? "No contenga espacios, " : ""
          }
        ${ochocaracteres === false ? "8 caracteres mínimo, " : ""}
        ${
          mayymin === false
            ? "al menos una letra mayúscula y letras minúsculas, "
            : ""
        }
        ${numeros === false ? "al menos un número, " : ""}
        ${noespecial === false ? "mínimo un caracter  especial." : ""} `,
        });
        setLoading(false);
        return null;
      }

      if (data.password !== data.repeatPassword) {
        ToastNotification({
          icon: "error",
          text: "Las contraseñas no coinciden",
        });
        setLoading(false);
        return null;
      }

      if (idPlanUser == "0") {
        console.log("idPlanUser GRATIS", idPlanUser);
        dispatch(
          RegisterClientPaymentPlan({
            referenceData: "",
            clientId: 0,
            name: data.name,
            phoneNumber: data.phone,
            email: data.email,
            company: data.company,
            password: data.password,
            isForeign: false,
            rfc: "",
            situacionFiscal: "",
            address: "",
            isFreeTrial: true,
            privacyPolicies: `${new Date()}`,
          })
        );
        setActiveRedirection(true);
      } else {
        console.log("idPlanUser COBRO", idPlanUser);
        dispatch(
          RegisterClient({
            referenceData: "",
            clientId: 0,
            name: data.name,
            phoneNumber: data.phone,
            email: data.email,
            company: data.company,
            password: data.password,
            isForeign: false,
            rfc: "",
            situacionFiscal: "",
            address: "",
            isFreeTrial: false,
            privacyPolicies: `${new Date()}`,
          })
        );
        setActiveUser(true);
        setActiveRedirection(true);
      }
    } catch (error) {
      setLoading(false);
      setCleanData(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeUser === true && activeRedirection === true) {
      if (stateRegisterClient.error) {
        console.log("ENTRAAQUI 1");
        setActiveUser(false);
        setActiveRedirection(false);
        setLoading(false);
        setCleanData(true);
      } else if (stateRegisterClient.items) {
        /* FUNCION PARA PODER REDIGIR AL SIGUIENTE STEPS */
        console.log("ENTRAAQUI 2");
        setActiveUser(false);
        setActiveRedirection(false);
        setLoading(false);
        const planSeleted = statePlanUser.items?.plans.find(
          (item) => item.id == idPlanUser
        );

        dispatch(SeletedPlanUser(planSeleted));
        navigate(`/BuySummary/${idPlanUser}`);
        setCleanData(true);
      }
    } else if (activeRedirection === true) {
      if (
        (stateRegisterPaymentClientePlan.loading === false &&
          stateRegisterClient.loading === false) ||
        stateRegisterClient.error ||
        stateRegisterPaymentClientePlan.error
      ) {
        // REDIRIGE DE NUEVOI A LA PAGINA ORIGINAL O LA RUTA INICIAL DE LA PAGINA
        console.log("ENTRAAQUI 3");
        setActiveRedirection(false);
        setLoading(false);
        setCleanData(true);
      }
    }
  }, [activeRedirection, stateRegisterClient, stateRegisterPaymentClientePlan]);

  const dynamicControls = [
    {
      Type: "TEXTO",
      Name: "name",
      Label: "Nombre Completo",
      Required: true,
      Value: "",
    },
    {
      Type: "PHONE",
      Name: "phone",
      Label: "Número de celular",
      Required: true,
      Value: "",
    },
    {
      Type: "EMAIL",
      Name: "email",
      Label: "Correo electrónico",
      Required: true,
      Value: "",
    },
    {
      Type: "TEXTO",
      Name: "company",
      Label: "Empresa o Negocio",
      Required: true,
      Value: "",
    },
  ];

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        padding: ".5em",
      }}
    >
      <Spinner loading={loading} />
      <Grid item xs={12} md={12} lg={12} sx={{ padding: "0em 0em 0em 0em" }}>
        <Box sx={{ width: "98%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">¡Regístrate ahora!</Typography>
            <Box sx={{ ml: 2 }}>
              <Tip
                title={
                  <Typography>
                    Estos datos son obligatorios para crear tu cuenta de{" "}
                    <b>Solusign</b>
                  </Typography>
                }
                placement="top"
                arrow
                enterTouchDelay={0}
              >
                <HelpOutlineIcon color="primary" sx={{ fontSize: 30 }} />
              </Tip>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6">
              ¡Completa tu registro y comienza a firmar hoy mismo!
            </Typography>
          </Box>
          <Box>
            <FormProvider {...formMethods}>
              <FormRegister
                data={dynamicControls}
                clickSubmit={onSubmit}
                cleanData={cleanData}
                setCleanData={setCleanData}
              />
            </FormProvider>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const FormRegister = (props) => {
  const [showPass, setshowPass] = useState(true);
  const [showPassDos, setshowPassDos] = useState(true);
  const [stateCaptcha, setStateCaptcha] = useState(false);
  const [blocked, setBlocked] = useState(true);

  const { control, handleSubmit, formState, setValue, register, watch, reset } =
    useForm({
      mode: "onBlur",
    });

  const checkPolicity = watch("policityPrivacity");

  useEffect(() => {
    if (props.cleanData == true) {
      reset();
      setValue("password", "");
      setValue("repeatPassword", "");
      setValue("email", "");
      setValue("phone", "52");
      props.setCleanData(false);
    }
  }, [props]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "space-around",
      }}
    >
      <Grid container>
        {props.data.length > 0 &&
          props.data.map((itemInput, index) => {
            switch (itemInput.Type.toUpperCase()) {
              case "TEXTO":
                return (
                  <Grid item lg={12} xs={12}>
                    <CustomInputField
                      inputType="text"
                      required={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "PHONE":
                return (
                  <Grid item lg={6} xs={12}>
                    <CustomInputField
                      inputType="phone"
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      required={true}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "EMAIL":
                return (
                  <Grid item lg={6} xs={12}>
                    <CustomInputField
                      inputType="email"
                      iconSpam={true}
                      required={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid item lg={12} xs={12}>
                    <CustomInputField
                      inputType="text"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
            }
          })}
        <Grid item lg={6} xs={12}>
          <Box>
            <InputField
              name="password"
              iconSpam={true}
              label="Contraseña"
              control={control}
              register={register}
              fullWidth
              sx={{ borderRadius: "12px" }}
              validations={{ required: true }}
              posIcon="end"
              type="password"
              isPassword={showPass}
              icon={
                showPass ? (
                  <IconButton onClick={() => setshowPass(false)}>
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setshowPass(true)}>
                    <Visibility />
                  </IconButton>
                )
              }
            />
            <FormHelperText sx={{ ml: 1 }}>
              La contraseña debe de contener al menos 8 caracteres, sin
              espacios, una mayúscula, una minúscula, un número y un carácter
              especial
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box>
            <InputField
              name="repeatPassword"
              iconSpam={true}
              label="Repetir contraseña"
              control={control}
              register={register}
              fullWidth
              sx={{ borderRadius: "12px" }}
              validations={{ required: true }}
              posIcon="end"
              type="password"
              isPassword={showPassDos}
              icon={
                showPassDos ? (
                  <IconButton onClick={() => setshowPassDos(false)}>
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setshowPassDos(true)}>
                    <Visibility />
                  </IconButton>
                )
              }
            />
            <FormHelperText sx={{ ml: 1 }}>
              La contraseña debe de contener al menos 8 caracteres, sin
              espacios, una mayúscula, una minúscula, un número y un carácter
              especial
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box sx={{ mt: 1 }}>
            <CustomInputField
              name="policityPrivacity"
              label={`
                  <Typography variant="h6">
                    He leído y acepto el
                    <a
                      href='${process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}'
                      target="_blank"
                      className='enlaceAvisos'
                    >
                      Aviso de Privacidad, Protección de datos
                    </a> y
                     <a
                    href='${process.env.REACT_APP_URL_SOLUSIGN_TERMINOS_CONDICIONES}'
                    target="_blank"
                    className='enlaceAvisos'
                  >
                  Términos y condiciones
                  </a>
                    de DocSolutions <spam className='enlaceAvisos'> * </spam>
                  </Typography>`}
              control={control}
              inputType="check"
              required={true}
              fullWidth
              sx={{ borderRadius: "12px" }}
              validations={{ required: true }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box
            fullWidth
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              justifyItems: "center",
            }}
          >
            <CapchaComponent
              setStateCaptcha={setStateCaptcha}
              setBlocked={setBlocked}
            />
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box
            fullWidth
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              justifyItems: "center",
            }}
          >
            <Typography variant="subtitle">
              ¿Ya tienes una cuenta?,{" "}
              <a
                style={{ color: "#E74C3C" }}
                href={process.env.REACT_APP_URL_SOLUSIGN}
                target="_blank"
              >
                Inicia sesión
              </a>
            </Typography>
          </Box>
          {/* <Box
            fullWidth
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              justifyItems: "center",
            }}
          >
            <Typography variant="subtitle">
              Al hacer clic en el botón Registrarme, usted acepta la{" "}
              <a
                style={{ color: "#E74C3C" }}
                href={process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}
                target="_blank"
                rel="noreferrer"
              >
                Política de privacidad
              </a>{" "}
              y los{" "}
              <a
                style={{ color: "#E74C3C" }}
                href={process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}
                target="_blank"
                rel="noreferrer"
              >
                Términos y condiciones.
              </a>
            </Typography>
          </Box> */}
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
              margin: 1,
              width: "100%",
            }}
          >
            {console.log("checkPolicity", !checkPolicity, blocked)}
            <CustomButton
              onClick={handleSubmit(props.clickSubmit)}
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                maxWidth: "454px",
              }}
              disabled={blocked  || !checkPolicity}
            >
              Registrarme
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
