import { stripeConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const ConfigStripe = (state = initialState, action) => {
  switch (action.type) {
    case stripeConstants.GET_STRIPE_KEY_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case stripeConstants.GET_STRIPE_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case stripeConstants.GET_STRIPE_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case stripeConstants.RESET_STRIPE_KEY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};


export const RegisterProjectClientPlan = (state = initialState, action) => {
  switch (action.type) {
    case stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };
    case stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };
    case stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    case stripeConstants.RESET_PROJECT_CLIENT_PLAN:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};