import React from "react";
import { Box, Typography, Button, styled } from "@mui/material";

const RootContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "grid",
  placeContent: "center",
}));

export const NotFoundPage = () => {
  
  return (
    <RootContainer>
      <Typography
        sx={{ fontSize: { xs: "20px !important", md: "28px !important" } }}
      >
        404 <span>|</span> Página no encontrada
      </Typography>
      <Button
        variant="contained"
        onClick={() => window.open(process.env.REACT_APP_URL_SOLUSIGN_LANDINPAGE)}
        sx={{ mt: 2, mx: "auto", height: 45 }}
      >
        Regresar al Solusign
      </Button>
    </RootContainer>
  );
};
