import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import iconError from "../assets/ErrorIcon.gif";
import iconSucces from "../assets/SuccessIcon.gif";
import iconEmail from "../assets/CorreoSend.png";
import WarningIcon from "../assets/WarningIcon.gif";
const MySwal = withReactContent(Swal);

export const AlertCustom = (props, successfn, errorfn) => {
  return MySwal.fire({
    iconHtml: (
      <img
        src={
          props.icon === "error"
            ? iconError
            : props.icon === "success"
            ? iconSucces
            : props.icon === "sendEmail"
            ? iconEmail
            : props.icon === "warning"
            ? WarningIcon
            : null
        }
        alt="ImagenIcon."
        className="swal2-icon-content-img"
      />
    ),
    title: props.title,
    text: props.text,
    html: props.html,
    showClass: {
      icon: "swal2-icon-alertNoti",
    },
    confirmButtonText:
      props.confirmButtonText === undefined
        ? "Aceptar"
        : props.confirmButtonText,
    customClass: {
      confirmButton: props.okbtnclass ?? "confirmButtonSweet",
      container: "backdropSweet",
      title: "tituloNotificacion",
    },
  })
    .then(successfn)
    .catch(errorfn);
};
