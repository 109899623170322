import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, styled } from "@mui/material";

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFF",
  width: "100%",
  height: "100%",
  margin: "0px auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  // border: '1px solid blue'
}));

const OutletWrapper = styled(Box)(({ theme }) => ({
  margin: "0px",
  [theme.breakpoints.down(1200)]: {
    margin: "0px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0px",
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0px",
  },
  // border: '1px solid red',
  //height: "100vh",
  overflow: "auto",
}));

export const HomeMarketPlace = () => {
  const location = useLocation();
  if (location.pathname.includes("login")) {
    return <>componente principal login</>;
  }

  return (
    <RootStyle>
        <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </RootStyle>
  );
};
