import { authenticationConstants } from "../constants";
import { AuthService } from "../../services/AuthService";

export const authentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const authService = AuthService.getInstance();
      const authenticationData = await authService.authenticationEncripted(
        authData
      );
      dispatch(success(authenticationData.Body));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };

  function request() {
    //Ingresar la petición hacia las API's (Aquí mostrar gif loader)
    return { type: authenticationConstants.AUTHENTICATION_REQUEST }; // => carpeta constants => AuthConstants.js
  }

  function success(authenticationData) {
    //Respuesta correcta de petición
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    }; // => carpeta constants => AuthConstants.js
  }

  function failure(error) {
    //Respuesta errone ade petición (Mostrar modal de cortesía)
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error }; // => carpeta constants => AuthConstants.js
  };

};


export const noAuthentication = (authData) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      dispatch(success(authData));
    } catch (error) {
      dispatch(failure(error.message));
    }
  };
  function request() {
    return { type: authenticationConstants.AUTHENTICATION_REQUEST };
  }
  function success(authenticationData) {
    return {
      type: authenticationConstants.AUTHENTICATION_SUCCESS,
      payload: authenticationData,
    };
  }
  function failure(error) {
    return { type: authenticationConstants.AUTHENTICATION_FAILURE, error };
  };

};