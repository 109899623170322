import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { PaymentService } from "../services/PaymentService";

export default function CapchaComponent({ setStateCaptcha, setBlocked }) {

  const catpchaChange = async (e) => {
    console.log("catpchaChange event:", e)
    if (e === undefined || e === null || e === "") {
      return;
    } else {

      setStateCaptcha(null);
      setBlocked(null);
      try {
        const validateCAPTCHA = PaymentService.getInstance();
        const resultCaptcha = await validateCAPTCHA.ValidateCAPTCHA({
          "referenceData": "string",
          "publicKey": e
        });
        console.log("resultCaptcha", resultCaptcha)
        if (resultCaptcha.success) {
          setStateCaptcha(true);
          setBlocked(false);
        } else {
          setStateCaptcha(false);
          setBlocked(true);
        }
      } catch (error) {
        console.log('errorCapcha', error)
        setStateCaptcha(false);
        setBlocked(true);
      }
    }
  };

  return (
    <div>
      <ReCAPTCHA
        style={{ width: '100%', display: "flex", textAlign: "center" }}
        className="iframeCaptcha"
        sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
        onChange={catpchaChange}
      />
    </div>
  );
}
