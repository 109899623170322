import { combineReducers } from "redux";

import { Authentication } from "./AuthenticationReducer";
import { GenericData } from "./GenericReducer";
import {
  DataPaymentGetPlans, DataPaymentRegisterClient, DataPaymentRegisterClientPlan,GetRegimenFiscal,
  DataPaymentGetTransactionCounts, DataPaymentRegTransactionCounts, DataPaymentGetBillings, RegisterBillingInformation
} from "./PaymentReducer";
import { ConfigStripe, RegisterProjectClientPlan } from "./StripeReducer"

const rootReducer = combineReducers({
  Authentication,
  GenericData,
  DataPaymentGetPlans,
  DataPaymentRegisterClient,
  DataPaymentRegisterClientPlan,
  DataPaymentGetTransactionCounts,
  DataPaymentRegTransactionCounts,
  DataPaymentGetBillings,
  RegisterBillingInformation,
  ConfigStripe,
  RegisterProjectClientPlan,
  GetRegimenFiscal,
});

export default rootReducer;
