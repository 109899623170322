import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class PaymentService {
  static instance;

  constructor() {
    this.baseUrl = "Payment";
  }

  static getInstance() {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService();
    }
    return PaymentService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async GetPlans(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetPlans`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    console.log(response.data)
    return response.data;
  }

  async RegisterClient(data) {
    const response = await GenericService.post({
      endpoint: `Payment/RegisterClient`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    console.log('response', response.data)
    return response.data;
  }

  async RegisterPaymentClientPlan(data) {
    console.log('datadata', data)
    const response = await GenericService.post({
      endpoint: `Payment/RegisterPaymentClientPlan`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    console.log('RESPONSECLIENTE', response)
    return response.data;
  }

  async RegisterBillingInformation(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/RegisterBillingInformation`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    // const files = await this.handleResponseForceEncripted(response);
    return response.data;
  }

  async GetRegimenFiscal(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetRegimenFiscal`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    // const files = await this.handleResponseForceEncripted(response);
    return response.data;
  }

  async GetPaymentStatus(data) {
    try {
      console.log('GetPaymentStatus', data)
      const response = await GenericService.post({
        endpoint: `Payment/GetPaymentStatus`,
        data: data,
        config: Config.configNoAuth(),
      });
      console.log('GetPaymentStatus', response.data)
      return response.data;
    } catch (error) {
      console.log('ERROR SERVER', error)
    }
  }


  async GetTransactionCounts(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetTransactionCounts`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    // const files = await this.handleResponseForceEncripted(response);
    return response.data;
  }

  async RegisterTransactionCount(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/RegisterTransactionCount`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async GetBilling(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetBilling`,
      data: Config.setParametersForceEncripted(data, true),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseForceEncripted(response);
    return files;
  }

  async ValidateCAPTCHA(data) {
    console.log("reuest catcha: ", data)
    console.log("reuest api: ", `${this.baseUrl}/ValidateCAPTCHA`)
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/ValidateCAPTCHA`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configAuth(),
    });
    const files = await this.handleResponseCaptcha(response);
    return files;
  }

  async handleResponseCaptcha(response) {
    console.log("handleResponseCaptcha---1----: ",response)
    let respObject = response.data.data;
    console.log("handleResponseCaptcha-------: ",respObject)
    if (!respObject.success) {
      const responseObject = {
        message: `${respObject.responseCodeMessage} ${respObject.requestUId ? respObject.requestUId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

}
