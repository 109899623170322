import React, { useState } from "react";
import {
  Box,
  Typography,
  CardActions,
  Divider,
  Grid,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomButtonSwitch } from "../../components/CustomButtonSwitch";

import { CustomButton } from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetConfigStripe,
  RegisterProjectClientPlan,
  SeletedPlanUser,
} from "../../store/actions";
import { useEffect } from "react";
import Spinner from "../../components/Spinner";
import { numeroFormateado } from "../../utils";
import moment from "moment/moment";

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 13,
  display: "flex",
  marginBottom: 10,
  [theme.breakpoints.down("xs")]: {
    display: "block",
    marginTop: 10,
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "50%",
  mt: 0.3,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  textAlign: "right",
  width: "50%",
  mt: 0.3,
  mr: 5,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    textAlign: "left",
    fontWeight: 400,
  },
}));

export const BuySummary = () => {
  let { idPlanUser } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [planUserSeleted, setPlanUserSeleted] = useState(null);
  const statePlans = useSelector((state) => state.DataPaymentGetPlans);
  const [fechaActual, setFechaActual] = useState(moment());
  const stateClient = useSelector(
    (state) => state.DataPaymentRegisterClient?.items
  );
  const stateStripe = useSelector((state) => state.ConfigStripe?.items);
  const stateClientProject = useSelector(
    (state) => state.RegisterProjectClientPlan?.items
  );

  useEffect(() => {
    setPlanUserSeleted(statePlans?.planUserSeleted);
  }, [statePlans?.planUserSeleted]);

  console.log("stateRedux", planUserSeleted, statePlans);

  const onSubmit = (data) => {
    dispatch(
      GetConfigStripe({
        referenceData: "",
      })
    );
    dispatch(
      RegisterProjectClientPlan({
        referenceData: "",
        clientId: stateClient?.clientId,
        planId: planUserSeleted.id,
      })
    );
    setLoading(true);
    console.log(data);
  };

  useEffect(() => {
    if (stateStripe !== null && stateClientProject !== null) {
      console.log("ENTRO AQUI");
      dispatch(SeletedPlanUser(planUserSeleted)); 
      navigate(`/PaymentUserData/${planUserSeleted.id}`);
      setLoading(false);
    }
  }, [loading, stateStripe, stateClientProject]);

  const onChangeSwitch = (newValue) => {
    const newState = statePlans.items?.plans.find(
      (item) => item.id === newValue
    );
    console.log("newValue", newState);
    setPlanUserSeleted(newState);
  };

  useEffect(() => {
    if(planUserSeleted?.periodicity == "Mensual"){
      setFechaActual(moment().clone().add(1, 'months'));
    }else if(planUserSeleted?.periodicity == "Anual"  ){
      setFechaActual(moment().clone().add(1, 'years'));
    }
  }, [planUserSeleted])
  

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Spinner loading={loading} />
      <Grid item xs={12} lg={8} md={12} >
        <Box>
          <Typography variant="h4">Resumen de la compra</Typography>
        </Box>
        <Box>
          <Typography variant="h4">Gracias por comprar en SoluSign</Typography>
          <br />

          <Box sx={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
            <BoxTitulo>
              <Typography variant="h6" sx={{ fontWeight: 550 }}>
                Información de tu plan
              </Typography>
            </BoxTitulo>
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            {console.log("planUserSeleted", planUserSeleted)}
            {planUserSeleted !== null ? (
              <BoxRexponsiveText
                Title={"Vigencia de tu plan:"}
               Subtitle={`${fechaActual.format('DD-MM-YYYY')}`}
              />
            ) : null}
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            <br />
            <br />
            <BoxTitulo>
              <Typography variant="h6" sx={{ fontWeight: 550 }}>
                Detalle de tu compra
              </Typography>
            </BoxTitulo>
            {idPlanUser == 2 ? (
              <>
                <Box sx={{ mt: 0.5 }}>
                  <Divider sx={{ color: "#C5C8CD" }} />
                </Box>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "300!important" }}
                    >
                      Opción de pago
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "200px",
                          height: "40px",
                        }}
                      >
                        <CustomButtonSwitch onChange={onChangeSwitch} />
                      </Box>
                    </Box>
                  </BoxTexto>
                </BoxContainer>
              </>
            ) : null}
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            <BoxRexponsiveText
              Title={"Plan seleccionado:"}
              Subtitle={planUserSeleted?.name}
            />
             <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            <BoxRexponsiveText
              Title={"Solicitudes:"}
              Subtitle={`${planUserSeleted?.maxTransactions} solicitudes`}
            />
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            <BoxRexponsiveText
              Title={"Fecha de contratación:"}
              Subtitle={moment().format("DD-MM-YYYY")}
            />
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            { planUserSeleted !== null ? (
              <BoxRexponsiveText
                Title={"Precio del plan:"}
                Subtitle={`${numeroFormateado(planUserSeleted?.price)} / mxn`}
              />
            ) : null}
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#C5C8CD" }} />
            </Box>
            { planUserSeleted !== null ? (
              <BoxRexponsiveText
                Title={"IVA:"}
                Subtitle={`${numeroFormateado(planUserSeleted?.iva)} / mxn`}
              />
            ) : null}
            <Box sx={{ mt: 0.5 }}>
              <Divider sx={{ color: "#E74C3C", border: 2 }} />
            </Box>
            { planUserSeleted !== null ? (
              <BoxRexponsiveText
                Title={"Total:"}
                Subtitle={`${numeroFormateado(planUserSeleted?.totalPay)} / mxn`}
              />
            ) : null}
          </Box>

          <CardActions sx={{ justifyContent: "center" }}>
            <CustomButton
              onClick={onSubmit}
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                maxWidth: "454px",
                height: "50px",
              }}
            >
              Continuar
            </CustomButton>
          </CardActions>
        </Box>
        {/* <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            {Parser(
              `Si tienes dudas con respecto a tu compra, llámanos al <span style='color:#C20E30'>(800) DS 10 000</span> o bien, un mail a <span style='color:#C20E30'>info@docsolutions.com</span>`
            )}
          </Typography>
        </Box> */}
      </Grid>
    </Grid>
  );
};

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="h6" sx={{ fontWeight: "300!important" }}>
          {props.Title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="h6" sx={{ fontWeight: "300!important" }}>
          {props.Subtitle}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};
