import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, styled, Typography, Button, Grid, Paper } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPlans,
  ResetNewCliente,
  ResetRegisterBilling,
  SeletedPlanUser,
} from "../../store/actions/PaymentActions";
import Spinner from "../../components/Spinner";
import { useState } from "react";
import {
  ResetProjectClientPlan,
  ResetStripeKey,
} from "../../store/actions/StripeActions";

const ButtonCustom = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  width: "100%",
  backgroundColor: "#C25869",
  borderColor: "#C25869",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    color: "#E74C3C",
    boxShadow: "none",
    borderRadius: "31px",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    color: "#E74C3C",
  },
  "&:focus": {
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    color: "#E74C3C",
  },
}));

const CardCustom = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "520px",
  background: "#FFFFFF",
  border: "1px solid #E0E0E0",
  boShadow: "0px 6px 31px rgba(0, 0, 0, 0.09)",
  padding: "1.2em",
}));

export const PaymentPlans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statePlans = useSelector((state) => state.DataPaymentGetPlans);
  const stateReducxs = useSelector((state) => state);
  const [typePlans, setTypePlans] = useState([]);

  let handleBuyClick = (item) => {
    dispatch(ResetNewCliente());
    dispatch(ResetProjectClientPlan());
    dispatch(ResetRegisterBilling());
    dispatch(ResetStripeKey());
    dispatch(SeletedPlanUser(item));
    if (item.id == 1) {
      navigate(`/RegistrationPlans/0`);
    } else {
      navigate(`/RegistrationPlans/${item.id}`);
    }
  };

  console.log('redux', stateReducxs);

  useEffect(() => {
    dispatch(
      GetPlans({
        referenceData: "",
        productId: 4,
      })
    );
  }, []);

  useEffect(() => {
    setTypePlans(statePlans.items?.plans);
  }, [statePlans.items]);

  return (
    <>
      <Spinner loading={statePlans.loading} />
      <Grid container justifyContent={"center"} alignItems={"items"}>
        {typePlans?.map((item) =>
          item.id != 6 && item.id != 7 && item.id != 5 ? (
            <Grid item lg={3} md={6} xs={12} key={item.id + 1 }>
              <CardPersonalize
                Title={item.name}
                Price={item.price}
                SubTitle={item.description}
                Periodicity={item.periodicity}
                ButtonTitle="Contratar ahora"
                Options={JSON.parse(item.descriptionFormat)}
                Data={item}
                ClickEvent={handleBuyClick}
              />
            </Grid>
          ) : null
        )}
      </Grid>
    </>
  );
};
// 667085
const CardPersonalize = (props) => {
  return (
    <Paper elevation={3} sx={{ borderRadius: "12px" }}>
      <CardCustom>
        <Box>
          <Typography variant="titulo" color="primary.textColor">
            {props.Title}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          color="primary.textColorSecondary"
          sx={{ marginTop: "0.5em" }}
        >
          {props.SubTitle}
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">$ {props.Price}/MXN</Typography>
          <Typography variant="subtitle1" color="primary" sx={{ ml: 1 }}>
            {props.Periodicity}
          </Typography>
        </Box>
        <CustomButton
          onClick={() => props.ClickEvent(props.Data)}
          sx={{ marginTop: "1em", marginBottom: "1em" }}
        >
          {props.ButtonTitle}
        </CustomButton>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600 }}
            color="primary.textColor"
          >
            Las caracteristicas incluyen:
          </Typography>
          <Grid
            container
            sx={{ padding: "0.5em", overflow: "auto", maxHeight: 260 }}
          >
            <Grid item xs={14} sx={{ paddingLeft: "1em" }}>
              {props.Options?.map((item, index) => {
                return (
                  <Box
                  key={index + 1}
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                      mt: 0.3,
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <CircleIcon color="primary" sx={{ width: 8 }} />
                    </Box>
                    <Typography
                      variant="enunciados"
                      color="primary.textColorSecondary"
                    >
                      {item}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </CardCustom>
    </Paper>
  );
};
