import React, { useCallback, useEffect, useState } from "react";
import {
  Tooltip,
  MenuItem,
  tooltipClasses,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  Typography,
  autocompleteClasses,
  Radio as Rd,
  RadioGroup,
  FormControl,
  FormLabel,
  useTheme,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Parser from "html-react-parser";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { fontSize: 14 },
  display: "inline-flex",
  alignItems: "center",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "140%",
  fontFamily: '"Montserrat",sans-serif',
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "9px",
    lineHeight: "144%",
  },
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: "4px 0",
  paddingLeft: theme.spacing(1),
}));

const Radio = styled(Rd)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
  },
}));

const InputText = styled(TextField)(({ theme }) => ({
  padding: "5px !important",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#C20E30!important",
  },
  ".MuiFormHelperText-root.Mui-error": {
    color: "#C20E30!important",
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
}));

const FormLabelCustom = styled(FormLabel)(({ theme }) => ({
  fontSize: 14,
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.color,
  },
}));

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPhone =
  "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}";
// const regexRFC = /^[A-ZÑ&]{3,4}\d{6}(?:[A-Z\d]{3})?$/
const regexPass = "^(?=.*[A-Z])(?=.*[a-z])(?=.*d)[^s]{8,}$";

export const InputField = (props) => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const [optionsSelect, setoptionsSelect] = useState([]);
  const {
    inputType,
    iconSpam,
    defaultValue,
    value,
    label,
    multiline,
    minRows,
    sxLabel,
    sxInput,
    optionsList,
    tooltipText,
    control,
    name,
    validations,
    helperText,
    icon,
    posIcon,
    onEnter,
    placeholder,
    onSelect,
    selectField,
    disabled,
    loading,
    onInputChange,
    open,
    onOpen,
    onClose,
    error,
    isPassword,
    checked,
    radioValues,
    minDate,
    maxDate,
    className,
    refCustom,
    required,
    register,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case "select":
        return (
          <Controller
            control={control}
            name={name}
            defaultValue=""
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <InputText
                {...restFields}
                error={error}
                label={label}
                fullWidth
                select
                type="text"
                variant="outlined"
                placeholder={placeholder}
                inputRef={ref}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  onSelect && onSelect(e);
                }}
              >
                {optionsSelect.map((opt, i) => (
                  <MenuItem key={`${opt?.Option}${i}`} value={opt?.Value}>
                    {opt.Option}
                  </MenuItem>
                ))}
              </InputText>
            )}
          />
        );
      case "check":
        return (
          <ControlLabel
            sx={{
              fontSize: "16px !important",
              mr: 1,
              my: "0px",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
            }}
            disabled={disabled}
            control={
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={validations}
                render={({ field: { ref, value, ...restFields } }) => (
                  <Check
                    value={value}
                    inputRef={ref}
                    checked={checked ?? value ?? defaultValue}
                    disabled={disabled}
                    size="small"
                    color="primary"
                    {...restFields}
                  />
                )}
              />
            }
            label={
              error ? (
                <Typography
                  color="primary"
                  style={{ padding: "5px", margin: "5px", fontSize: 16 }}
                >
                  {Parser(label)}
                </Typography>
              ) : (
                <Box sx={{ padding: "5px", margin: "5px", fontSize: 16 }}>
                  {Parser(label)}
                </Box>
              )
            }
          />
        );
      case "radio":
        return (
          <Controller
            sx={{
              fontSize: "16px !important",
              m: "0",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            control={control}
            name={name}
            defaultValue={null}
            rules={validations}
            render={({ field }) => (
              <FormControl component="fieldset" error={error}>
                <FormLabelCustom component="legend" error={error}>
                  {error ? (
                    <Box
                      sx={{
                        fontSize: "16px !important",
                        m: "0",
                        fontFamily: '"Montserrat",sans-serif',
                        width: "100% !important",
                      }}
                    >
                      {label}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 700,
                        color: "black",
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                    </Box>
                  )}{" "}
                </FormLabelCustom>
                <RadioGroup {...field}>
                  {radioValues.map((rv) => {
                    return (
                      <FormControlLabel
                        value={rv.Value}
                        control={<Radio color="primary" />}
                        label={
                          <Box
                            sx={{
                              fontSize: "16px !important",
                              m: "0",
                              fontFamily: '"Montserrat",sans-serif',
                              width: "100% !important",
                            }}
                          >
                            {rv.Option}
                          </Box>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )}
          />
        );
      case "radio-flex":
        return (
          <Controller
            sx={{
              fontSize: "16px !important",
              m: "0",
              fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            control={control}
            name={name}
            defaultValue={null}
            rules={validations}
            render={({ field }) => (
              <FormControl component="fieldset" error={error}>
                <FormLabelCustom component="legend" error={error}>
                  {error ? (
                    <Box
                      sx={{
                        fontSize: "16px !important",
                        m: "0",
                        fontFamily: '"Montserrat",sans-serif',
                        width: "100% !important",
                      }}
                    >
                      {label}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 700,
                        color: "black",
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                      {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    </Box>
                  )}{" "}
                </FormLabelCustom>
                <RadioGroup {...field}>
                  <Box>
                    {radioValues.map((rv) => {
                      return (
                        <FormControlLabel
                          value={rv.Value}
                          control={<Radio color="primary" />}
                          label={
                            <Box
                              sx={{
                                fontSize: "16px !important",
                                m: "0",
                                fontFamily: '"Montserrat",sans-serif',
                                width: "100% !important",
                              }}
                            >
                              {rv.Option}
                            </Box>
                          }
                        />
                      );
                    })}
                  </Box>
                </RadioGroup>
              </FormControl>
            )}
          />
        );
      case "phone":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                {...register(name, {
                  required: "Requerido",
                  validate: (value) => {
                    return (
                      new RegExp(regexPhone).test(value) ||
                      `${label} dato inválido. Número de teléfono a 10 digitos. ej. 5567883730`
                    );
                  },
                  minLength: {
                    value: 10,
                    message: "Número de teléfono a 10 digitos. ej. 5567883730",
                  },
                  setValueAs: (value) => value?.toUpperCase(),
                })}
                className={className}
                type={"text"}
                error={error}
                defaultValue={defaultValue}
                required={required}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                fullWidth
                placeholder={placeholder}
                disabled={disabled}
                autoComplete="new-password"
                helperText={helperText}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                variant="outlined"
                InputProps={{
                  inputProps: {
                    maxLength: 10,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                {...register(name, {
                  // required: "Requerido",
                  // minLength: { value: 10, message: 'Número de teléfono a 10 digitos. ej. 5567883730' },
                  setValueAs: (value) => value?.toUpperCase(),
                })}
                className={className}
                type={"text"}
                error={error}
                defaultValue={defaultValue}
                required={required}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                fullWidth
                autoComplete="new-password"
                helperText={helperText}
                label={label}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    // maxLength: 10,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        );
      case "clabe":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                {...register(name, {
                  // required: "Requerido",
                  minLength: {
                    value: 19,
                    message: "Clabe a 19 digitos. ej. 4521369874569856321",
                  },
                  setValueAs: (value) => value.toUpperCase(),
                })}
                className={className}
                type={"text"}
                error={error}
                id={name}
                name={name}
                defaultValue={defaultValue}
                required={required}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                fullWidth
                autoComplete="new-password"
                helperText={helperText}
                label={label?.toUpperCase()}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    maxLength: 19,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  // inputComponent: TextMaskCustomClabe,
                }}
              />
            )}
          />
        );
      case "email":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                {...register(name, {
                  required: "Requerido",
                  validate: (value) => {
                    return (
                      new RegExp(regexEmail).test(value) ||
                      `${label} dato inválido. ${
                        label === "Correo electrónico"
                          ? "ej. ejemplo@ejemplo.com"
                          : ""
                      }`
                    );
                  },
                  setValueAs: (value) => value,
                })}
                className={className}
                type={"text"}
                error={error}
                defaultValue={defaultValue}
                required={required}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                fullWidth
                autoComplete="new-password"
                helperText={helperText}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                variant="outlined"
                InputProps={{
                  inputProps: {
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        );
      case "date":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                inputFormat="dd/MM/yyyy"
                inputProps={{ placeholder: "dd/mm/aaaa" }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}{" "}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                components={{
                  OpenPickerIcon: CalendarTodayOutlinedIcon,
                }}
                {...restFields}
                onChange={(e) => {
                  if (e) {
                    const d = new Date();
                    d.setFullYear(e.getFullYear(), e.getMonth(), e.getDate());
                    d.setHours(0);
                    d.setMinutes(0);
                    d.setSeconds(0);
                  }
                  onChange(e);
                  onSelect && onSelect(e);
                }}
                inputRef={ref}
                // defaultValue={defaultValue ?? null}
                disabled={disabled}
                renderInput={(params) => {
                  return (
                    <InputText
                      onKeyUp={onEnter}
                      {...params}
                      error={error}
                      // placeholder="dd/mm/aaaa"
                      // placeholder={placeholder}
                      fullWidth
                      sx={{ ...(sxInput ?? {}) }}
                      // InputProps={{placeholder: "dd/mm/aaaa"}}
                      InputLabelProps={{ sx: sxLabel }}
                    />
                  );
                }}
              />
            )}
          />
        );
      case "CURP":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                className={className}
                type={isPassword ? "password" : "text"}
                error={error}
                defaultValue={defaultValue}
                fullWidth
                // inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                value={defaultValue}
                label={label}
                InputProps={{
                  startAdornment:
                    posIcon === "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon === "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
        case "password":
          return (
            <Controller
              control={control}
              name={name}
              rules={validations}
              defaultValue={defaultValue}
              render={({
                field: { ref, onChange, ...restFields },
                fieldState,
              }) => (
                <TextField
                  {...restFields}
                  {...register(name, {
                    required: required ? "Requerido" : "",
                    validate: (value) => {
                      console.log("valuevalue", value);
                      const ochocaracteres = /.{8,}/.test(value);
                      const mayymin =
                        /(?:[A-Z])/.test(value) && /(?:[a-z])/.test(value);
                      const numeros = /(?:\d)/.test(value);
                      const noespecial = /[^A-Za-z\d\s]/.test(value);
                      const espacios = /\s/.test(value);
                      console.log(ochocaracteres, mayymin, numeros, noespecial);
  
                      const valida =
                        ochocaracteres && mayymin && numeros && noespecial;
  
                      console.log("valida--------:  ", valida);
  
                      console.log("valida-------espacios-:  -", espacios, "--");
  
                      if (valida === false || espacios) {
                        return `Revisa que tu contraseña cumpla lo siguiente: ${
                          espacios ? "No contenga espacios, " : ""
                        }
                  ${ochocaracteres === false ? "8 caracteres mínimo, " : ""}
                  ${
                    mayymin === false
                      ? "al menos una letra mayúscula y letras minúsculas, "
                      : ""
                  }
                  ${numeros === false ? "al menos un número, " : ""}
                  ${
                    noespecial === false
                      ? "mínimo un caracter especial ( $ % # _ = + ¡ ! ¿ @ ?)."
                      : ""
                  } `;
                      } else {
                        return true;
                      }
                    },
                  })}
                  type={showPassword ? "text" : "password"}
                  error={error ?? fieldState.invalid}
                  helperText={helperText ?? fieldState.error?.message}
                  defaultValue={defaultValue}
                  fullWidth
                  name={name}
                  inputRef={ref}
                  multiline={multiline}
                  minRows={minRows ?? 1}
                  onKeyUp={onEnter}
                  placeholder={placeholder}
                  disabled={disabled}
                  required={required}
                  autoComplete="new-password"
                  label={
                    label != undefined && label != null && label != "" ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "140%",
                          [theme.breakpoints.down("sm")]: {
                            fontWeight: 400,
                            fontSize: "10px",
                            lineHeight: "144%",
                          },
                        }}
                      >
                        {label}
                        {""}
                        {iconSpam ? (
                          <spam style={{ color: "#DC1717", marginLeft: 3 }}>
                            *
                          </spam>
                        ) : null}
                        {tooltipText && (
                          <Tip
                            title={tooltipText}
                            placement="top"
                            arrow
                            enterTouchDelay={0}
                          >
                            <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                          </Tip>
                        )}
                      </Box>
                    ) : null
                  }
                  InputProps={{
                    inputProps: {
                      outlinet: "none",
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true, sx: sxLabel }}
                  sx={{
                    "& $input": {
                      height: multiline ? "auto" : "25px",
                    },
                    ...(sxInput ?? {}),
                  }}
                />
              )}
            />
          );
      default:
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <InputText
                {...restFields}
                // {...register(name,  { 
                //  required: "Requerido",
                //   setValueAs: (value) => value, 
                // })}
                className={className}
                type={isPassword ? "password" : "text"}
                error={error}
                defaultValue={defaultValue}
                fullWidth
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                helperText={helperText}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}{" "}
                    {iconSpam ? (
                      <spam style={{ color: "#C20E30", marginLeft: 3 }}>
                        {" "}
                        *{" "}
                      </spam>
                    ) : null}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  startAdornment:
                    posIcon === "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon === "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  // inputProps: { style: { textTransform: "uppercase" } },
                }}
                variant="outlined"
              />
            )}
          />
        );
    }
  };

  useEffect(() => {
    /*  setoptionsSelect(["Contrato", "Consentimiento de convenio"]); */
    if (optionsList?.length > 0) {
      const opts = optionsList.map(({ Name, ...item }) => ({
        ...item,
        name: Name,
      }));
      setoptionsSelect(opts);
    }
  }, [optionsList]);

  if (inputType === "check") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>{getInputType()}</Box>
    );
  }

  return getInputType();
};

// const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
//   return (
//     <IMaskInput
//       {...props}
//       mask="(00) 0000 - 0000"
//       definitions={{
//         "#": /[1-9]/,
//       }}
//       inputRef={ref}
//       overwrite
//     />
//   );
// });
