export const paymentConstants = {
  PAYMENT_GETPLANS_REQUEST: "PAYMENT_GETPLANS_REQUEST",
  PAYMENT_GETPLANS_SUCCESS: "PAYMENT_GETPLANS_SUCCESS",
  PAYMENT_GETPLANS_FAILURE: "PAYMENT_GETPLANS_FAILURE",
  PAYMENT_SELECTEDPLAN_USER_SUCCESS: "PAYMENT_SELECTED_PLANUSER_SUCCESS",
  PAYMENT_RESETPLAN_USER: "PAYMENT_RESETPLAN_USER",

  PAYMENT_REGISTERCLIENT_REQUEST: "PAYMENT_REGISTERCLIENT_REQUEST",
  PAYMENT_REGISTERCLIENT_RESET: "PAYMENT_REGISTERCLIENT_RESET",
  PAYMENT_REGISTERCLIENT_SUCCESS: "PAYMENT_REGISTERCLIENT_SUCCESS",
  PAYMENT_REGISTERCLIENT_FAILURE: "PAYMENT_REGISTERCLIENT_FAILURE",

  PAYMENT_REGISTERPAYMENTCLIENTPLAN_REQUEST: "PAYMENT_REGISTERPAYMENTCLIENTPLAN_REQUEST",
  PAYMENT_REGISTERPAYMENTCLIENTPLAN_SUCCESS: "PAYMENT_REGISTERPAYMENTCLIENTPLAN_SUCCESS",
  PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE: "PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE",
  PAYMENT_REGISTERPAYMENTCLIENTPLAN_RESET: "PAYMENT_REGISTERPAYMENTCLIENTPLAN_RESET",

  PAYMENT_GETTRANSACTIONCOUNTS_REQUEST: "PAYMENT_GETTRANSACTIONCOUNTS_REQUEST",
  PAYMENT_GETTRANSACTIONCOUNTS_SUCCESS: "PAYMENT_GETTRANSACTIONCOUNTS_SUCCESS",
  PAYMENT_GETTRANSACTIONCOUNTS_FAILURE: "PAYMENT_GETTRANSACTIONCOUNTS_FAILURE",

  PAYMENT_REGISTERTRANSACTIONCOUNT_REQUEST: "PAYMENT_REGISTERTRANSACTIONCOUNT_REQUEST",
  PAYMENT_REGISTERTRANSACTIONCOUNT_SUCCESS: "PAYMENT_REGISTERTRANSACTIONCOUNT_SUCCESS",
  PAYMENT_REGISTERTRANSACTIONCOUNT_FAILURE: "PAYMENT_REGISTERTRANSACTIONCOUNT_FAILURE",

  PAYMENT_GETBILLING_REQUEST: "PAYMENT_GETBILLING_REQUEST",
  PAYMENT_GETBILLING_SUCCESS: "PAYMENT_GETBILLING_SUCCESS",
  PAYMENT_GETBILLING_FAILURE: "PAYMENT_GETBILLING_FAILURE",

  PAYMENT_REGISTER_BILLING_REQUEST: "PAYMENT_REGISTER_BILLING_REQUEST",
  PAYMENT_REGISTER_BILLING_SUCCESS: "PAYMENT_REGISTER_BILLING_SUCCESS",
  PAYMENT_REGISTER_BILLING_FAILURE: "PAYMENT_REGISTER_BILLING_FAILURE",
  PAYMENT_REGISTER_BILLING_RESET: 'PAYMENT_REGISTER_BILLING_RESET',

  PAYMENT_GET_REGIMENFISCAL_REQUEST: "PAYMENT_GET_REGIMENFISCAL_REQUEST",
  PAYMENT_GET_REGIMENFISCAL_SUCCESS: "PAYMENT_GET_REGIMENFISCAL_SUCCESS",
  PAYMENT_GET_REGIMENFISCAL_FAILURE: "PAYMENT_GET_REGIMENFISCAL_FAILURE",
};