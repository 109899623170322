import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import './App.css';
import packageJson from "../package.json";

import { ThemeConfig } from "./theme/ThemeConfig.jsx";
import storepersist from "./store";

import { NotFoundPage } from "./components/NotFoundPage.jsx";

import { HomeMarketPlace } from "./pages/home/HomeMarketPlace.jsx";
import { PaymentPlans } from "./pages/Plans/PaymentPlans.jsx";
import { RegistrationPlans } from "./pages/Plans/RegistrationPlans.jsx";
import { BuySummary } from "./pages/Plans/BuySummary.jsx";
import { PaymentMethod } from "./pages/Plans/PaymentMethod.jsx";
import { PaymentUserData } from "./pages/Plans/PaymentUserData.jsx";
import BillingData from "./pages/Plans/BillingData";

const App = () => {
  const { store, persistor } = storepersist;

  useEffect(() => {
    caching()
  }, [])

  let caching = () => {
    let version = localStorage?.getItem('versionApp');
    console.log("version: ", version)
    console.log(" packageJson.version: ", packageJson.version)
    if (version != packageJson.version) {
      if ('caches' in window) {
        try {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
              caches.delete(name);
            })
          });
        } catch (e) {
          console.log("Error: ", e)
        }

        console.log("Ejecuto reload.")
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('versionApp', packageJson.version);
    }
  };

  return (
    <BrowserRouter
      basename={
        process.env.NODE_ENV === "development"
          ? "/"
          : `${process.env.REACT_APP_BASENAME}`
      }
    >
      <Provider store={store}>
        <PersistGate loading="null" persistor={persistor}>
          
          <ThemeConfig>
            <Routes>
              <Route path="/" element={<HomeMarketPlace />}>
              <Route index element={<Navigate to="404" />} />
                <Route path="RegistrationPlans/:idPlanUser" element={<RegistrationPlans />} />
                <Route path="BuySummary/:idPlanUser" element={<BuySummary />} /> {/* CONFIRMACION DE COMPRA */}
                <Route path="PaymentUserData/:idPlanUser" element={<PaymentUserData />} />{/* DATOS DE TARJETA */}

                <Route path="BillingData" element={<BillingData />} /> {/* DATOS DE FACTURACION */}
                <Route path="PaymentMethod" element={<PaymentMethod />} /> {/* METODO DE PAGO */}
                <Route path="PaymentPlans" element={<PaymentPlans />} /> planes disponibles

              </Route>
              <Route path="*" element={<Navigate to="404" />} />
              <Route path="404" element={<NotFoundPage />} />

            </Routes>
          </ThemeConfig>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
