import crypto from "crypto-browserify";

export function getHash(string) {
  return crypto.createHash('sha256').update(string).digest('hex');
}

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const B64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const numeroFormateado = (numero) => {
  return numero.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const sumarDiasAFecha = (dias) => {
  const fechaActual = new Date();
  const fechaFutura = new Date(fechaActual);
  fechaFutura.setDate(fechaActual.getDate() + dias);
  const dia = fechaFutura.getDate();
  const mes = fechaFutura.getMonth() + 1; 
  const año = fechaFutura.getFullYear();

  const formattedDate = `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${año}`;
  return formattedDate;
}