import { paymentConstants } from "../constants";
import { PaymentService } from "../../services/PaymentService";
import { Box, Typography } from "@mui/material";
import { AlertCustom } from '../../components/AlertCustom';

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const ResetNewCliente = () => {
  return {
    type: paymentConstants.PAYMENT_REGISTERCLIENT_RESET,
  };
};

export const SeletedPlanUser = (data) => {
  return {
    type: paymentConstants.PAYMENT_SELECTEDPLAN_USER_SUCCESS,
    payload: data,
  };
};

export const ResetPlanUser = () => {
  return {
    type: paymentConstants.PAYMENT_RESETPLAN_USER,
  };
};

export const ResetRegisterPaymentClient = () => {
  return {
    type: paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_RESET,
  };
};

export const GetPlans = (data) => {
  console.log(data)
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_GETPLANS_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.GetPlans(data);
      dispatch(success(paymentData.data, paymentConstants.PAYMENT_GETPLANS_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_GETPLANS_FAILURE));
    }
  };
};

export const RegisterClientPaymentPlan = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_REGISTERCLIENT_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.RegisterClient(data);
      console.log('ALTA', paymentData.data)
      if (paymentData.data.clientId !== null) {
        console.log('ALTA 2')
        if (paymentData.data.clientId !== null) {
          console.log('ALTA 3')
          const responseService = await paymentService.RegisterPaymentClientPlan({
            referenceData: "",
            clientId: paymentData.data.clientId,
            planId: 1,
            paymentData: null,
          });
          console.log('ALTA 4', responseService.data, responseService)
          if (responseService.data.projectId) {
            console.log('ENTRO A DAR DE ALTA')
            dispatch(success(paymentData.data, paymentConstants.PAYMENT_REGISTERCLIENT_SUCCESS));
            dispatch(success(responseService.data, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_SUCCESS));
            AlertCustom({
              icon: "success",
              okbtntext: "Aceptar",
              html: (
                <Box>
                  <Typography textAlign={'center'} variant="h4" sx={{ fontWeight: 530, mt: 1, textAlign: 'center', maxWidth: 400, mx: "auto", fontFamily: '"Montserrat",sans-serif' }}>
                    Registro exitoso
                  </Typography>
                  <br />
                  <Typography textAlign={'center'} variant="subtitle" sx={{ fontWeight: 450, textAlign: 'center', maxWidth: 400, mx: "auto", mt: 1, fontFamily: '"Montserrat",sans-serif' }}>
                    A partir de este momento puedes comenzar a usar SoluSign®
                  </Typography>
                </Box>
              ),
            },
              (result) => {
                if (result.isConfirmed) {
                  window.open(process.env.REACT_APP_URL_SOLUSIGN, '_blank');
                } else if (result.dismiss === "backdrop" && result.isDismissed) {
                  window.open(process.env.REACT_APP_URL_SOLUSIGN, '_blank');
                }
              }
            );
          } else {
            if (responseService.data.responseCodeMessage) {
              AlertCustom({
                icon: "error",
                okbtntext: "Aceptar",
                text: `Ha ocurrido un error. ${responseService.data.responseCodeMessage}`
              });
              dispatch(failure(responseService.data.responseCodeMessage, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE));
              return
            }
          }
        }
      } else {
        AlertCustom({
          icon: "error",
          okbtntext: "Aceptar",
          text: `Ha ocurrido un error. ${paymentData.data.responseCodeMessage}`
        });
        dispatch(failure(paymentData.data.responseCodeMessage, paymentConstants.PAYMENT_REGISTERCLIENT_FAILURE));
      }
    } catch (error) {
      console.log('errorRegistro', error)
      AlertCustom({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
      dispatch(failure(error.message, paymentConstants.PAYMENT_REGISTERCLIENT_FAILURE));
      dispatch(failure(error.message, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE));
    }
  };
};


export const RegisterClient = (data) => {
  console.log('datadata', data )
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_REGISTERCLIENT_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.RegisterClient(data);
      console.log('paymentDataClient', paymentData)
      if (paymentData.data.responseCodeMessage) {
        AlertCustom({
          icon: "error",
          okbtntext: "Aceptar",
          text: `Ha ocurrido un error. ${paymentData.data.responseCodeMessage}`
        });
        dispatch(failure(paymentData.data.responseCodeMessage, paymentConstants.PAYMENT_REGISTERCLIENT_FAILURE));
        return
      }
      dispatch(success(paymentData.data, paymentConstants.PAYMENT_REGISTERCLIENT_SUCCESS));
      AlertCustom({
        icon: "success",
        okbtntext: "Aceptar",
        html: (
          <Box>
            <Typography textAlign={'center'} variant="h4" sx={{ fontWeight: 530, mt: 1, textAlign: 'center', maxWidth: 400, mx: "auto", fontFamily: '"Montserrat",sans-serif' }}>
              Registro exitoso
            </Typography>
            <br />
            <Typography textAlign={'center'} variant="subtitle" sx={{ fontWeight: 450, textAlign: 'center', maxWidth: 400, mx: "auto", mt: 1, fontFamily: '"Montserrat",sans-serif' }}>
              Bienvenido a Solusign, tu solución de firma digital
            </Typography>
          </Box>
        ),
      });
    } catch (error) {
      console.log(error)
      AlertCustom({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
      dispatch(failure(error.message, paymentConstants.PAYMENT_REGISTERCLIENT_FAILURE));
    }
  };
};


export const RegisterPaymentClientPlan = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.RegisterPaymentClientPlan(data);
      if (paymentData.data.responseCodeMessage) {
        AlertCustom({
          icon: "error",
          okbtntext: "Aceptar",
          text: `Ha ocurrido un error. ${paymentData.data.responseCodeMessage}`
        });
        dispatch(failure(paymentData.data.responseCodeMessage, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE));
        return
      }
      console.log('paymentData', paymentData)
      dispatch(success(paymentData.data, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_SUCCESS));
    } catch (error) {
      console.log('error', error)
      dispatch(failure(error, paymentConstants.PAYMENT_REGISTERPAYMENTCLIENTPLAN_FAILURE));
      AlertCustom({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const GetTransactionCounts = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.GetTransactionCounts(data);
      dispatch(success(paymentData.Body, paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_FAILURE));
    }
  };
};

export const RegisterTransactionCount = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.RegisterTransactionCount(data);
      dispatch(success(paymentData.Body, paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_GETTRANSACTIONCOUNTS_FAILURE));
    }
  };
};

export const GetBilling = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_GETBILLING_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.GetBilling(data);
      dispatch(success(paymentData.Body, paymentConstants.PAYMENT_GETBILLING_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_GETBILLING_FAILURE));
    }
  };
};

export const ResetRegisterBilling = () => {
  return {
    type: paymentConstants.PAYMENT_REGISTER_BILLING_RESET,
  };
};

export const RegisterBillingInformation = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_REGISTER_BILLING_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.RegisterBillingInformation(data);
      console.log('RegisterBillingInformation', paymentData)
      dispatch(success(paymentData.data, paymentConstants.PAYMENT_REGISTER_BILLING_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_REGISTER_BILLING_FAILURE));
      console.log('error', error)
      AlertCustom({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};

export const GetRegimenFiscal = (data) => {
  return async (dispatch) => {
    dispatch(request(paymentConstants.PAYMENT_GET_REGIMENFISCAL_REQUEST));
    try {
      const paymentService = PaymentService.getInstance();
      const paymentData = await paymentService.GetRegimenFiscal(data);
      console.log('GetRegimenFiscal', paymentData)
      dispatch(success(paymentData.data, paymentConstants.PAYMENT_GET_REGIMENFISCAL_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, paymentConstants.PAYMENT_GET_REGIMENFISCAL_FAILURE));
      console.log('error', error)
      AlertCustom({
        icon: "error",
        okbtntext: "Aceptar",
        text: 'Ha ocurrido un error.' + error
      });
    }
  };
};