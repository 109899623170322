import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { InputField } from "../../components/InputField";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRegimenFiscal,
  RegisterBillingInformation,
} from "../../store/actions/PaymentActions";
import Spinner from "../../components/Spinner";
import { CustomInputField } from "../../components/CustomInputField";

export default function BillingData() {
  const formMethods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const clientIdDs = useSelector((state) => state.DataPaymentClient?.items);
  const registerBilling = useSelector(
    (state) => state.RegisterBillingInformation
  );

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      RegisterBillingInformation({
        referenceData: "",
        billingClient_Id: null,
        client_Id: clientIdDs?.client_Id,
        creationDate: new Date().toISOString(),
        active: true,
        name: data.name,
        paternalSurname: data.lastNames,
        maternalSurname: "",
        rfc: data.tipoPersona === "extranjero" ? "" : data.rfc,
        cfdi: "",
        regimenFiscal: data.tipoPersona === "extranjero" ? "" : data.regFiscal,
        phone: data.phone,
        email: data.email,
        address: data.address,
        cp: data.cp,
        tipoPersona: data.tipoPersona,
        nombreEmpresa: data.tipoPersona === "moral" ? data.name : "",
        isForeign: data.tipoPersona === "extranjero",
      })
    );
  };

  useEffect(() => {
    if (registerBilling.items !== null) {
      console.log("ENTRA AQUI 3");
      navigate("/auth/PaymentUserData");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [registerBilling.items]);

  const dynamicControls = [
    {
      Type: "TEXTO",
      Name: "name",
      Label: "Nombre (s)",
      Required: true,
      Value: "",
    },
    {
      Type: "TEXTO",
      Name: "lastNames",
      Label: "Apellidos",
      Required: false,
      Value: "",
    },
    {
      Type: "RFC",
      Name: "rfc",
      Label: "RFC",
      Required: true,
      Value: "",
    },
    {
      Type: "SELECT",
      Name: "regFiscal",
      Label: "Régimen  fiscal",
      Required: true,
      Value: "",
      Options: [
        {
          Option: "Selecciona un tipo de persona",
          Value: 1,
        },
      ],
    },
    {
      Type: "PHONE",
      Name: "phone",
      Label: "Número de celular",
      Required: true,
      Value: "",
    },
    {
      Type: "EMAIL",
      Name: "email",
      Label: "Correo electrónico",
      Required: true,
      Value: "",
    },
    {
      Type: "CP",
      Name: "cp",
      Label: "Código postal",
      Required: true,
      Value: "",
    },
    {
      Type: "TEXTO",
      Name: "address",
      Label: "Dirección",
      Required: true,
      Value: "",
    },
  ];

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Spinner loading={loading} />
      <Grid item xs={12} lg={9} md={12}>
        <Box sx={{ m: "2em" }}>
          <Typography variant="h2">Facturación</Typography>
        </Box>
        <Card
          elevation={3}
          sx={(theme) => ({ minWidth: 275, mt: "2em", m: "1em" })}
        >
          <CardContent>
            <FormProvider {...formMethods}>
              <FormRegister data={dynamicControls} clickSubmit={onSubmit} />
            </FormProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const FormRegister = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateRegimenes = useSelector((state) => state.GetRegimenFiscal);
  const [regimenes, setRegimenes] = useState([]);
  const { control, handleSubmit, watch, formState, register } = useForm({
    mode: "onBlur",
  });
  const tipoPersona = watch("tipoPersona");

  useEffect(() => {
    dispatch(
      GetRegimenFiscal({
        referenceData: "",
        codigoTP: "",
      })
    );
  }, []);

  useEffect(() => {
    if (tipoPersona === "moral") {
      const newState = stateRegimenes.items?.catalogoRegimenFiscal.filter(
        (item) => item.esPersonaFisica === false
      );
      const newDatos = newState.map((item) => {
        return {
          Option: `${item.codigo} - ${item.descripcion}`,
          Value: item.codigo,
        };
      });

      setRegimenes(newDatos);
    } else if (tipoPersona === "fisica") {
      const newState = stateRegimenes.items?.catalogoRegimenFiscal.filter(
        (item) => item.esPersonaFisica === true
      );
      const newDatos = newState.map((item) => {
        return {
          Option: `${item.codigo} - ${item.descripcion}`,
          Value: item.codigo,
        };
      });
      setRegimenes(newDatos);
    }
  }, [tipoPersona]);

  return (
    <>
      <Grid container>
        <Grid item lg={12} xs={12} md={12}>
          <InputField
            iconSpam={true}
            label="Tipo de persona"
            inputType="radio-flex"
            radioValues={[
              {
                Value: "fisica",
                Option: "Física ",
              },
              {
                Value: "moral",
                Option: "Moral",
              },
              {
                Value: "extranjero",
                Option: "Soy extranjero",
              },
            ]}
            control={control}
            register={register}
            name={"tipoPersona"}
            validations={{ required: true }}
            defaultValue={""}
            error={!!formState.errors["tipoPersona"]}
            helperText={formState.errors["tipoPersona"]?.message}
          />
        </Grid>
        {props.data.length > 0 &&
          props.data.map((itemInput, indexInput) => {
            switch (itemInput.Type.toUpperCase()) {
              case "TEXTO":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <InputField
                      inputType="text"
                      iconSpam={true}
                      control={control}
                      disabled={
                        (itemInput.Name === "rfc" &&
                          tipoPersona === "extranjero") ||
                        (itemInput.Name === "lastNames" &&
                          tipoPersona === "moral")
                      }
                      register={register}
                      name={`${itemInput.Name}`}
                      label={
                        itemInput.Name === "name" && tipoPersona === "moral"
                          ? "Nombre de empresa o asociación"
                          : itemInput.Label
                      }
                      validations={{
                        required:
                          itemInput.Name === "rfc" &&
                          tipoPersona === "extranjero"
                            ? false
                            : itemInput.Required,
                      }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "RFC":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <CustomInputField
                      inputType="rfc"
                      iconSpam={true}
                      control={control}
                      disabled={
                        (itemInput.Name === "rfc" &&
                          tipoPersona === "extranjero") ||
                        (itemInput.Name === "lastNames" &&
                          tipoPersona === "moral")
                      }
                      register={register}
                      name={`${itemInput.Name}`}
                      label={
                        itemInput.Name === "name" && tipoPersona === "moral"
                          ? "Nombre de empresa o asociación"
                          : itemInput.Label
                      }
                      validations={{
                        required:
                          itemInput.Name === "rfc" &&
                          tipoPersona === "extranjero"
                            ? false
                            : itemInput.Required,
                      }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "SELECT":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <InputField
                      inputType="select"
                      disabled={tipoPersona === "extranjero"}
                      validations={{
                        required:
                          tipoPersona === "extranjero"
                            ? false
                            : itemInput.Required,
                      }}
                      iconSpam={true}
                      control={control}
                      optionsList={regimenes}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "PHONE":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <CustomInputField
                      inputType="phone"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "EMAIL":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <InputField
                      inputType="email"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              case "CP":
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <CustomInputField
                      inputType="PostalCode"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid item lg={6} xs={12} md={5}>
                    <InputField
                      inputType="text"
                      iconSpam={true}
                      control={control}
                      register={register}
                      name={`${itemInput.Name}`}
                      label={itemInput.Label}
                      validations={{ required: itemInput.Required }}
                      defaultValue={itemInput.Value}
                      error={!!formState.errors[`${itemInput.Name}`]}
                      helperText={
                        formState.errors[`${itemInput.Name}`]?.message
                      }
                    />
                  </Grid>
                );
            }
          })}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: 3,
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            variant="outlined"
            onClick={() => navigate("/auth/PaymentUserData")}
            sx={{
              fontSize: 17,
              height: 48,
              marginTop: "1.5em",
              marginBottom: "1.5em",
              minWidth: 300,
            }}
          >
            Omitir
          </Button>
        </Box>
        <Box sx={{ p: 1 }}>
          <CustomButton
            onClick={handleSubmit(props.clickSubmit)}
            sx={{
              marginTop: "1.5em",
              marginBottom: "1.5em",
              minWidth: 300,
            }}
          >
            Continuar
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};
