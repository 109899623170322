import React from "react";
import SwitchSelector from "react-switch-selector";

const options = [
  {
    label: "Mensual",
    value: 2,
    selectedBackgroundColor: "#D65745",
    fontColor: "#000",
  },
  {
    label: "Anual",
    value: 6,
    selectedBackgroundColor: "#D65745",
    fontColor: "#000",
  }
];

export const CustomButtonSwitch = (props) => {
  return (
    <SwitchSelector
      onChange={props.onChange}
      options={options}
      initialSelectedIndex={0}
      backgroundColor={"#ECECEC"}
      fontColor={"#000"}
      selectedFontColor={"#ECECEC"}
      fontSize={15}     
      style={{width: 200}} 
    />
  );
}
