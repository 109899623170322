import { GenericService } from "./GenericService";
import { Config } from "./Config";
import { BodySecurityResponseForceEncripted } from "../utils/encripted";

export class StripeService {
  static instance;

  constructor() {
    this.baseUrl = "Stripe";
  }

  static getInstance() {
    if (!StripeService.instance) {
      StripeService.instance = new StripeService();
    }
    return StripeService.instance;
  }

  async handleResponse(response) {
    let respObject = response.data.data;

    if (!respObject.IsOK) {
      const responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async handleResponseForceEncripted(response) {
    var respObject = BodySecurityResponseForceEncripted(response.data.data);
    if (!respObject.IsOK) {
      var responseObject = {
        message: `${respObject.Messages} ${respObject.TansactionId ? respObject.TansactionId : ""
          } ${respObject.statusText ? respObject.statusText : ""}`,
      };
      const error = respObject.Body || responseObject;
      return Promise.reject(error);
    }
    return respObject;
  }

  async GetConfigStripe(data) {
    const response = await GenericService.post({
      endpoint: `${this.baseUrl}/GetConfigStripe`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    console.log(response.data)
    return response.data;
  }

  async RegisterProjectClientPlan(data) {
    console.log(data,'data')
    const response = await GenericService.post({
      endpoint: `Payment/RegisterProjectClientPlan`,
      data: Config.setParametersForceEncripted(data, false),
      config: Config.configNoAuth(),
    });
    console.log('responseClienteSecrect',response.data)
    return response.data;
  }


}
