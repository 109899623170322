import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Grid,
  styled,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { CustomButton } from "../../components/CustomButton";
import { AlertCustom } from "../../components/AlertCustom";
import { useNavigate } from "react-router-dom";
import { PaymentService } from "../../services/PaymentService";
import Spinner from "../../components/Spinner";
import { numeroFormateado, sumarDiasAFecha } from "../../utils";
import { UseImage } from "../../components/UseImage";
import moment from "moment/moment";

const InputText = styled(TextField)(({ theme }) => ({
  padding: "5px 5px 5px 0px !important",
  "& .MuiOutlinedInput-input": {
    height: 22,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 5,
    fontSize: 14,
    border: 0,
    "& fieldset legend span": {
      display: "none",
      border: 0,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
    border: 0,
  },
  "& label.Mui-error": {
    color: "#C20E30 !important",
    border: 0,
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
    border: 0,
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 13,
  display: "flex",
  [theme.breakpoints.down("xs")]: {
    display: "block",
    marginTop: 10,
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.mainLyrics,
  wordWrap: "break-word",
  width: "50%",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.mainLyrics,
  wordWrap: "break-word",
  textAlign: "right",
  width: "50%",
  mr: 5,
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    textAlign: "left",
    fontWeight: 400,
  },
}));

export const PaymentUserData = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const stateRedux = useSelector((state) => state);
  // const [clientSecret, setClientSecret] = useState("");
  const [fechaActual, setFechaActual] = useState(moment());

  const clientSecret = useSelector(
    (state) => state.RegisterProjectClientPlan?.items?.clientSecret
  );

  const publiskey = useSelector(
    (state) => state.ConfigStripe?.items?.publicSecretKey
  );

  const statePlanSelect = useSelector(
    (state) => state.DataPaymentGetPlans?.planUserSeleted
  );

  console.log("stateRedux", stateRedux);
  console.log("publiskey", publiskey);
  console.log("clientSecret", clientSecret);
  console.log("statePlanSelect", statePlanSelect);

  useEffect(() => {
    // NO SIRVE
    // fetch("/config").then(async (r) => {
    // const { publishableKey } = await r.json();
    if (publiskey) {
      setStripePromise(loadStripe(publiskey, { locale: "es" }));
    }
    // });
  }, [publiskey]);

  useEffect(() => {
    // CONSUMIR API DE DS CLOUD, PARA RETORNAR LA LLAVE SECRETA
    // fetch("/create-payment-intent", {
    //   method: "POST",
    //   body: JSON.stringify({}),
    // }).then(async (result) => {
    //   var { clientSecret } = await result.json();
    //   setClientSecret(clientSecret);
    // });
  }, []);

  useEffect(() => {
    if(statePlanSelect?.periodicity == "Mensual"){
      setFechaActual(moment().clone().add(1, 'months'));
    }else if(statePlanSelect?.periodicity == "Anual"  ){
      setFechaActual(moment().clone().add(1, 'years'));
    }
  }, [statePlanSelect])

  return (
    <Grid container>
      <Grid item lg={12}>
        <Box
          sx={{
            justifyContent: "flex-start",
            alignContent: "flex-start",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{ justifyContent: "left", textAlign: "left" }}
            variant="h4"
          >
            Confirmación de tu compra
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Card
          sx={(theme) => ({
            background: theme.palette.primary.mainLyrics,
            borderRadius: "30px",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
            minHeight: "300px",
            width: "100%",
          })}
          elevation={4}
        >
          <CardContent>
            <Box sx={{ mb: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 550 }}>
                Detalles de la tarjeta
              </Typography>
            </Box>
            {clientSecret && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret,
                }}
              >
                <CardDetailsForm />
              </Elements>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          alignSelf: "center",
          mt: "1em",
        }}
      >
        <Card
          sx={(theme) => ({
            width: "100%",
            height: "auto",
            background: '#858585',
            borderRadius: "10px",
          })}
          elevation={6}
        >
          <CardContent sx={{ height: "100%", padding: "2em" }}>
            <Typography variant="h4" sx={{ color: "#FFFFFF" }}>
              {statePlanSelect?.name}
            </Typography>
            <Typography variant="subtitle" sx={{ color: "#FFFFFF" }}>
              Tu plan tiene una vigencia de {statePlanSelect?.validityDays} días
              partir de hoy:
            </Typography>
            <BoxRexponsiveText
              Title={"Vigencia de tu plan:"}
              Subtitle={`${fechaActual.format('DD-MM-YYYY')}`}
            />
            <BoxRexponsiveText
              Title={"Plan seleccionado:"}
              Subtitle={`${statePlanSelect?.maxTransactions} solicitudes`}
            />
            <BoxRexponsiveText
              Title={"Precio del plan:"}
              Subtitle={`${numeroFormateado(statePlanSelect?.price)} / mxn`}
            />
            <BoxRexponsiveText
              Title={"IVA:"}
              Subtitle={`${numeroFormateado(statePlanSelect?.iva)} / mxn`}
            />
            <Box sx={{ mt: 1.3 }}>
              <Divider sx={{ color: "#C5C8CD", height: 10 }} />
            </Box>
            <BoxRexponsiveText
              Title={"Total a pagar:"}
              Subtitle={`${numeroFormateado(statePlanSelect?.totalPay)} / mxn`}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="h6" sx={{ fontWeight: "550!important" }}>
          {props.Title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="h6" sx={{ fontWeight: "550!important" }}>
          {props.Subtitle}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};

const CheckOutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    console.log("elements********: ", elements);

    const { error } = await stripe.confirmPayment({
      // `Elements` Instancia que se utilizó para crear el elemento de pago
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      //TODO: SI ES CORRECTO DIRIGE A OTRA PAGINA
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h5" sx={{ fontWeight: "550!important" }}>
        Nombre del propietario
      </Typography>
      <InputText
        fullWidth
        helperText={""}
        label={""}
        InputProps={{
          inputProps: { style: { textTransform: "uppercase" } },
        }}
        variant="outlined"
      />
      <PaymentElement options={{}} />
      {errorMessage && (
        <Box sx={{ color: "#E74C3C", margin: "1em 0em" }}>{errorMessage}</Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1em",
        }}
      >
        <CustomButton
          onClick={handleSubmit}
          sx={{ marginTop: "1.5em", marginBottom: "1.5em", maxWidth: "454px" }}
        >
          Realizar pago
        </CustomButton>
      </Box>
    </form>
  );
};

const CardDetailsForm = () => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateClientRegister = useSelector(
    (state) => state.DataPaymentRegisterClient?.items
  );
  const statePlanUser = useSelector(
    (state) => state.DataPaymentGetPlans?.planUserSeleted
  );

  const clientSecret = useSelector(
    (state) => state.RegisterProjectClientPlan?.items?.clientSecret
  );

  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(null);
  const [isConfirmedPayment, setIsConfirmedPayment] = useState(false);

  const [txtDomicilio, setTxtDomicilio] = useState("");
  const [txtPropietario, setTxtPropietario] = useState("");

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      console.log("ENTRO EN ESTE ERROR");
      setLoading(false);
      AlertCustom({
        icon: "error",
        title: "Ocurrio un problema interno",
      });
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Creacion de metodo de pago.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      metadata: {
        ClientId: stateClientRegister?.clientId,
        Propietario: txtPropietario,
      },
    });
    console.log("error", error);

    if (error?.code) {
      setProcessing(false);
      setLoading(false);
      AlertCustom({
        icon: "warning",
        title: "Ocurrio un problema",
        text:
          error?.message ??
          "Revisa que los datos de tu tarjeta esten correctos",
      });
      return;
    }

    console.log("paymentMethod", paymentMethod);
    console.log("clientSecret******: ", clientSecret);

    if (!isConfirmedPayment) {
      //Confirmacion de metodo de pago
      const confirmedCardPayment = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethod.id,
        }
      );

      console.log("confirmedCardPayment******: ", confirmedCardPayment);
      console.log(
        "confirmedCardPayment******: ",
        confirmedCardPayment?.paymentIntent?.id
      );
      if (confirmedCardPayment.error) {
        //Todo: se agrega una nueva api para el registro de estos logs en DSCloud.
        setProcessing(false);
        setLoading(false);
        AlertCustom({
          icon: "error",
          title: "Ocurrio un problema",
          text:
            confirmedCardPayment.error?.message ??
            "Revisa la vigencia de tu tarjeta y que el saldo sea suficiente",
        });
        return;
      } else {
        console.log(confirmedCardPayment?.paymentIntent.id);
        localStorage.setItem(
          "confirmedCardPaymentId",
          confirmedCardPayment?.paymentIntent?.id
        );
        setIsConfirmedPayment(true);
        getStatusPayment();
      }
    }
  };

  useEffect(() => {
    if (timerSeconds === 0) {
      console.log("Hace la peticion");
      getStatusPayment();
      setTimerSeconds(3);
    }
    if (!timerSeconds) return;
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timerSeconds]);

  const getStatusPayment = useCallback(async () => {
    const paymentService = PaymentService.getInstance();
    console.log(
      "confirmedCardPaymentId",
      localStorage.getItem("confirmedCardPaymentId")
    );
    console.log("CLIENTE", stateClientRegister, stateClientRegister?.clientId);

    const resStatus = await paymentService.GetPaymentStatus({
      referenceData: "",
      client_Id: stateClientRegister?.clientId,
      referenceId: localStorage.getItem("confirmedCardPaymentId"),
    });

    console.log("resStatus", resStatus);
    console.log("stateClientRegister", stateClientRegister);

    if (resStatus.data.status === "Pagado") {
      AlertCustom(
        {
          icon: "success",
          title: "Se realizó tu pago de manera exitosa",
          // text: `No. de transacción: ${resStatus?.data?.paymentStatus_Id}`,
        },
        (result) => {
          if (result.isConfirmed) {
            window.open(process.env.REACT_APP_URL_SOLUSIGN, "_blank");
          } else if (result.dismiss === "backdrop" && result.isDismissed) {
            window.open(process.env.REACT_APP_URL_SOLUSIGN, "_blank");
          }
        }
      );
      setTimerSeconds(-1);
      setLoading(false);
      navigate("/RegistrationPlans/0");
      // localStorage.removeItem("confirmedCardPaymentId");
    } else {
      setTimerSeconds(3);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} id="form-css">
      <Spinner loading={loading} />

      <Typography variant="h6" sx={{ fontWeight: "550!important" }}>
        Nombre del propietario *
      </Typography>
      <InputText
        fullWidth
        name={"txtNombrePropietario"}
        InputProps={{
          inputProps: { style: { textTransform: "uppercase" } },
        }}
        variant="outlined"
        validations={{ required: true }}
        defaultValue={""}
        onChange={(event) => {
          console.log(
            "txtNombrePropietario event.target.value: ",
            event.target.value
          );
          setTxtPropietario(event.target.value.toUpperCase());
        }}
        placeholder="Nombre del propietario"
      />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h6" sx={{ mt: 1.5, fontWeight: "550!important" }}>
        Número de tarjeta *
      </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
          <Box>
            <UseImage type="Image" height={25} src={"Logo_AMEX.png"} />
          </Box>
          <Box>
            <UseImage type="Image" height={25} src={"logo_Mastercard.png"} />
          </Box>
          <Box>
            <UseImage type="Image" height={15} src={"logo_visa.png"} />
          </Box>
        </Box>
      </Box>
      <CardNumberElement style={{ base: { fontSize: "18px" } }} />
      <Box
        sx={{
          display: "grid !important",
          gridTemplateColumns: "50% 50%",
          width: "100%",
          alignItems: "center",
          position: "sticky",
          zIndex: 25,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-start",
            paddingRight: "0.8em",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mt: 1.5, fontWeight: "550!important" }}
          >
            Fecha de vencimiento *
          </Typography>
          <CardExpiryElement style={{ base: { fontSize: "18px" } }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-start",
            paddingLeft: "0.8em",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mt: 1.5, fontWeight: "550!important" }}
          >
            CVV / CVC *
          </Typography>
          <CardCvcElement
            style={{ base: { fontSize: "18px", width: "100%" } }}
          />
        </Box>
      </Box>

      {/* <Typography variant="h6" sx={{ mt: 1.5, fontWeight: "550!important" }}>
        Domicilio
      </Typography>
      <InputText
        fullWidth
        name={"txtDomicilio"}
        InputProps={{
          inputProps: { style: { textTransform: "uppercase" } },
        }}
        variant="outlined"
        validations={{ required: true }}
        defaultValue={""}
        onChange={(event) => {
          console.log("txtDomicilio event.target.value: ", event.target.value);
          setTxtDomicilio(event.target.value.toUpperCase());
        }}
        placeholder="Domicilio"
      /> */}
      {error && <Box sx={{ color: "#E74C3C", margin: "1em 0em" }}>{error}</Box>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "1em",
        }}
      >
        <CustomButton type="submit" sx={{ maxWidth: "454px" }}>
          Realizar pago
        </CustomButton>
      </Box>
    </form>
  );
};
