import { stripeConstants } from "../constants";
import { StripeService } from "../../services/StripeService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const ResetStripeKey = () => {
  return {
    type: stripeConstants.RESET_STRIPE_KEY,
  };
};

export const ResetProjectClientPlan = () => {
  return {
    type: stripeConstants.RESET_PROJECT_CLIENT_PLAN,
  };
};

export const GetConfigStripe = (data) => {
  console.log(data)
  return async (dispatch) => {
    dispatch(request(stripeConstants.GET_STRIPE_KEY_REQUEST));
    try {
      const stripeService = StripeService.getInstance();
      const paymentData = await stripeService.GetConfigStripe(data);
      dispatch(success(paymentData.data, stripeConstants.GET_STRIPE_KEY_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, stripeConstants.GET_STRIPE_KEY_FAILURE));
    }
  };
};

export const RegisterProjectClientPlan = (data) => {
  console.log('datadata', data)
  return async (dispatch) => {
    dispatch(request(stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_REQUEST));
    try {
      const stripeService = StripeService.getInstance();
      const paymentData = await stripeService.RegisterProjectClientPlan(data);
      dispatch(success(paymentData.data, stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, stripeConstants.REGISTER_PROJECT_CLIENT_PLAN_FAILURE));
    }
  };
};
