import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton } from "../../components/CustomButton";
import LogoComponent from "../../assets/LogoComponent.svg";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import {
  GetConfigStripe,
  RegisterProjectClientPlan,
} from "../../store/actions";

const FormControlLabelCustom = styled(FormControlLabel)(({ theme }) => ({
  background: theme.palette.secondary.background,
  margin: "5px 2px 5px 0px",
}));

export const PaymentMethod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([0]);
  const stateStripe = useSelector((state) => state.ConfigStripe);
  const statePlanSelect = useSelector(
    (state) => state.DataPaymentGetPlans?.planUserSeleted
  );
  const stateClient = useSelector(
    (state) => state.DataPaymentRegisterClient?.items
  );

  console.log("statePlanSelect", statePlanSelect);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onSubmit = (data) => {
    dispatch(
      GetConfigStripe({
        referenceData: "",
      })
    );
    dispatch(
      RegisterProjectClientPlan({
        referenceData: "",
        clientId: stateClient?.clientId,
        planId: statePlanSelect?.id,
      })
    );
    if (stateStripe.items !== null) {
      navigate("/auth/PaymentUserData");
    }
  };

  return (
    <Grid container alignContent={"center"} justifyContent={"center"}>
      <Spinner loading={stateStripe.loading} />
      <Grid item xs={12} lg={5} sx={{ m: "1em" }}>
        <Card
          sx={(theme) => ({
            background: theme.palette.primary.mainLyrics,
            borderRadius: "30px",
            padding: "1.5em",
            boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
            minHeight: "300px",
            width: "100%",
          })}
        >
          <Box
            sx={{
              display: "grid !important",
              gridTemplateColumns: "50% 50%",
              width: "100%",
              alignItems: "center",
              position: "sticky",
              zIndex: 25,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                zIndex: 25,
                paddingLeft: "15px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "130%",
              }}
            >
              <img src={LogoComponent} alt="imageLogo" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: "20px",
                zIndex: 25,
              }}
            >
              <Typography variant="titulo">
                ${statePlanSelect?.price}
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "13px",
                    marginLeft: "0.5em",
                  }}
                >
                  {statePlanSelect?.currency}{" "}
                </span>
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={(theme) => ({
              color: "red",
              width: "100%",
              border: `0.5px solid ${theme.palette.divider.main}`,
              margin: "1em 0em",
            })}
          />
          <CardContent>
            <Typography variant="h5" sx={{ marginBottom: 4 }}>
              Hola, solicitaste realizar el pago de un servicio
            </Typography>
            <Typography variant="h4">Selecciona el método de pago:</Typography>

            <RadioGroup
              defaultValue="female"
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
            >
              <FormControlLabelCustom
                value="female"
                control={<Radio />}
                label="Tarjeta de crédito / débito"
              />
              {/* <FormControlLabelCustom
                value="male"
                control={<Radio />}
                label="Stripe"
              /> */}
            </RadioGroup>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <CustomButton
              onClick={onSubmit}
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                maxWidth: "454px",
              }}
            >
              Pagar ahora
            </CustomButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid
        item
        lg={6}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Card
          sx={(theme) => ({
            width: "352px",
            height: "300px",
            background: theme.palette.primary.main,
            borderRadius: "10px",
          })}
        >
          <CardContent sx={{ height: "100%", padding: "2em" }}>
            <Typography
              variant="h2"
              sx={{ color: "#FFFFFF", padding: "1.5em 0em" }}
            >
              Resumen de compra
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#FFFFFF", padding: "0.5em 0em" }}
            >
              Concepto
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#FFFFFF", padding: "0.5em 0em" }}
            >
              {statePlanSelect?.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#FFFFFF", padding: "0.5em 0em" }}
            >
              Monto total
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#FFFFFF", padding: "0.5em 0em" }}
            >
              ${statePlanSelect?.price}
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "11px",
                  lineHeight: "13px",
                  marginLeft: "0.5em",
                  padding: "0.5em 0em",
                }}
              >
                {statePlanSelect?.currency}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
