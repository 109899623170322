import axios from "axios";

export class GenericService {
  static baseUrl = process.env.REACT_APP_API;
  // 'https://techhub.docsolutions.com/DSCloud/Desarrollo/WebApiV2/Payment/api/';
  // static baseUrl = 'https://localhost:7073/api/';

  static async get({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrl}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async getDoc({ endpoint, config }) {
    const response = await axios.get(`${this.baseUrl}${endpoint}`, config);
    return {
      data: response,
      status: response.status,
    };
  }

  static async post({ endpoint, data, config }) {
    console.log(data)
    const response = await axios.post(
      `${this.baseUrl}${endpoint}`,
      data,
      config
    );
    return {
      data: response,
      status: response.status,
    };
  }

  static async contentVideo({ endpoint, data, token }) {
    console.log(endpoint, data, token);
    return axios({
      method: 'post',
      url: endpoint,
      data: data,
      headers: new Headers({
        'Authorization': `Basic ${token}`,
        'Accept': "*",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      })
    })
  }

}
